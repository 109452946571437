strong {
  font-weight: 600;
}

ul li {
  list-style-type: "\02192 \ ";
  /*circle;*/
  list-style-position: inside;
}

#background-image {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
  /* background-image: url('../landing.jpeg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}